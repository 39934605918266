import { DraggableWindow } from './DraggableWindow.js';
import { windowContentLoader } from './windowContentLoader.js';
import { CollapsibleManager } from './CollapsibleManager.js';

document.addEventListener('DOMContentLoaded', function() {
    const contentLoader = new windowContentLoader();
    new DraggableWindow(document.querySelector('#mainWindow'));

    function loadBio() {
        contentLoader.loadContent('bio.html').then(() => {
            new CollapsibleManager('.collapsible');
            document.querySelector('a#read-more').addEventListener('click', toggleBio);
        });
    }

    function loadContact() {
        contentLoader.loadContent('contact.html');
    }

    function toggleBio() {
        const bio = document.querySelector('#bio-hidden');
        const a = document.querySelector('a#read-more');
        a.innerHTML = bio.style.display !== 'none' ? 'Read more...' : 'Read less...';
        bio.style.display = bio.style.display === 'none' ? 'block' : 'none';
    }
    
    document.querySelector('a#load-bio').addEventListener('click', loadBio);
    document.querySelector('a#load-contact').addEventListener('click', loadContact);
    loadBio(); // Hi! :)
}, false);
