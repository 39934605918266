// CollapsibleManager.js
export class CollapsibleManager {
  constructor(selector) {
    this.collapsibles = document.querySelectorAll(selector);
    this.initialize();
  }

  initialize() {
    for (let i = 0; i < this.collapsibles.length; i++) {
      this.collapsibles[i].addEventListener("click", function() {
        this.classList.toggle("active");
        const content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }
}
