// DraggableWindow.js
export class DraggableWindow {
    constructor(element) {
      this.element = element;
      this.currentPosX = 0;
      this.currentPosY = 0;
      this.previousPosX = 0;
      this.previousPosY = 0;
      this.keySequence = [];
      this.keyCombo = ':q';
      
      // Bind events, not the cleanest way but it works
      const handle = this.element.querySelector('.window-top') || this.element;
      handle.onmousedown = this.dragMouseDown.bind(this);
      document.addEventListener('keydown', this.keyDownHandler.bind(this));
    }
    
    dragMouseDown(e) {
      e.preventDefault();
      this.previousPosX = e.clientX;
      this.previousPosY = e.clientY;
      document.onmouseup = this.closeDragElement.bind(this);
      document.onmousemove = this.elementDrag.bind(this);
    }
    
    doubleClickHandler(e) {
      e.preventDefault();
      this.closeWindow();
    }
    
    elementDrag(e) {
      e.preventDefault();
      this.currentPosX = this.previousPosX - e.clientX;
      this.currentPosY = this.previousPosY - e.clientY;
      this.previousPosX = e.clientX;
      this.previousPosY = e.clientY;
      this.element.style.top = (this.element.offsetTop - this.currentPosY) + 'px';
      this.element.style.left = (this.element.offsetLeft - this.currentPosX) + 'px';
    }
    
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
    
    keyDownHandler(e) {
      if (this.element.style.display === 'none') {
        return;
      }
      
      // Dear reader: don´t worry
      // as you can see, this is just a fun easter egg
      // I'm not doing anything malicious here
      this.keySequence.push(e.key);
      if (this.keySequence.length > this.keyCombo.length) {
        this.keySequence.shift(); // Keep only the latest relevant keys
      }
      
      if (this.keySequence.join('') === this.keyCombo) {
        alert('VIM mode activated! Thanks for visiting :)'); // Just for fun
        this.closeWindow();
      }
    }
    
    closeWindow() {
      this.element.style.display = 'none';
    }
  }
  