// windowContentLoader.js
export class windowContentLoader {
    constructor() {
        this.element = document.querySelector('.window-content');
        this.title = document.querySelector('.window-top .title');
        this.shell = document.querySelector('.window-shell');
    }

    async loadContent(path) {
        try {
            //console.log(`Fetching content from: ${path}`);
            const response = await fetch(path);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const html = await response.text();
            const bytes = response.headers.get('content-length');

            this.element.innerHTML = html;
            this.title.innerHTML = `&nbsp;■ window 1 - ${path}`;
            this.shell.innerHTML = `&nbsp;'mgz.sh/${path}' 67L, ${bytes}B`;
        } catch (error) {
            //console.error('Error loading content:', error);
        }
    }
}
